@use "../base/variable" as *;
@use "../base/theme_orange" as *;
@use "../base/icomoon-style";
@use "../base/mixin" as *;
@use "../base/keyframes";
.sidebar {
  width: 200px;
  min-width: 200px;
  margin-right: 90px;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: left;
  .l-main & {
    @include media(tab) {
      display: none;
    }
  }
  @include media(1166px) {
    margin-right: 40px;
  }
  @include media(tab) {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.6rem;
    background-color: $bc_primary_light;
    z-index: 50;
  }
  &__title {
    margin-bottom: 2em;
    padding-bottom: 1em;
    border-bottom: 1px solid $bc_boder_gray;
    @include media(tab) {
      margin-bottom: 1em;
      border-color: $bc_primary;
    }
  }
  &__item {
    &--text {
      a {
        display: inline-block;
        padding: .5em 0;
      }
    }
  }
  &__itemName {
    display: flex;
    padding: .5em 0;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.has-subList {
      &::after {
        content: "\e900";
        display: inline-block;
        font-family: "icomoon-lc-casual";
        font-size: 0.8em;
      }
      &.active::after {
        content: "\e903";
      }
    }
  }
  &__subList {
    display: none;
    margin: 1em 0;
    padding: 1.5em 2em;
    background-color: #F2F2F2;
    font-size: 1.3rem;
    font-weight: 500;
    @include media(tab) {
      background-color: $bc_white;
    }
  }
  &__subItem {
    >a {
      display: block;
      padding: 1em 0;
    }
  }
}

